import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";

export const meta: MetaFunction = () => {
  return [
    { title: "Routepia" },
    { name: "description", content: "Welcome to Remix!" },
  ];
};

export default function Index() {
  return (
    <div className="px-8">
      <div className="flex items-center justify-between pb-2 pt-4">
        <h1 className="text-xl font-semibold">Routepia</h1>

        <div>
          <Link className="no-underline" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}
